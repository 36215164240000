import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/perliia/perliia/src/templates/api.mdx.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Une route est un trajet réalisé par un véhicule en particulier ou non.
Le trajet réalise un ensemble d'activité avec un lieu de départ et différent lieux de passages`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      